<template>
  <div>
    <div
      class="event-detail forsiga"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content">
        <div class="first-banner mb-12">
          <div class="first-banner__text">
            <div class="first-banner__title">Крепость здоровья</div>
            <div class="first-banner__description">
              <span
                class="px-4 py-2 d-inline-block"
                style="border-radius: 30px; border: 1px solid #fff"
                >30 сентября 2023</span
              ><br /><br />
              Вместе достигнем большего
            </div>
            <div
              class="button first-banner__button button_white-blue"
              @click="toRegister(true)"
              v-if="!!!Object.keys(user).length && !!$route.query.access"
            >
              Регистрация на мероприятие
            </div>
          </div>
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/krepost/banner_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/krepost/banner_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/krepost/banner.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/krepost/banner.jpg`"
            />
          </picture>
        </div>
        <div class="event-detail__description mb-12">
          «Крепость здоровья» — мультидисциплинарная конференция в
          онлайн-формате, посвященная терапии социально-значимых заболеваний
        </div>

        <div class="event-detail__warning d-flex mt-6" v-if="false">
          <svg
            class="mr-2"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM15.3595 23.5C14.8366 23.5 14.5 23.066 14.5 22.5553V14.7511C14.5 14.2404 14.8366 13.8064 15.3595 13.8064H16.6144C17.1373 13.8064 17.5 14.2404 17.5 14.7511V22.5553C17.5 23.066 17.1373 23.5 16.6144 23.5H15.3595ZM16 8C16.8284 8 17.5 8.67157 17.5 9.5C17.5 10.3284 16.8284 11 16 11C15.1716 11 14.5 10.3284 14.5 9.5C14.5 8.67157 15.1716 8 16 8Z"
              fill="#2186AF"
            />
          </svg>
          <span>
            Коллеги, если у вас возникают проблемы с регистрацией, авторизацией,
            а также проблемы с подключением к трансляции, просим вас обращаться
            в тех поддержку по номеру телефона:
            <a href="tel:88002202014">8-800-220-20-14</a>.<br />
            Обращаем внимание, что техническая поддержка доступна только
            30&nbsp;сентября&nbsp;с&nbsp;9:30&nbsp;до&nbsp;15:00&nbsp;по&nbsp;МСК
          </span>
        </div>

        <div class="events-list__title mb-4">Направления</div>
        <div class="events-list mb-8">
          <KrepostEvent
            v-for="(event, ind) in eventsList"
            :key="ind"
            :event="event"
            :ended="true"
          />
        </div>
        <div
          class="event-detail__timer"
          v-if="
            Object.keys(timerResult).length &&
            timerResult.days &&
            timerResult.days > 0
          "
        >
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Трансляция началась"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{  helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                   helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть трансляцию
          </div>
        </div>
        <div class="hobl-activities mt-16">
          <div class="hobl-activities__list">
            <div class="hobl-activities__item">
              <div class="hobl-activity__icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/krepost/icon1.svg`" />
              </div>
              <div
                class="hobl-activity__button button button_white-blue"
                @click="toWidget('find_word', 'Цитаты')"
              >
                Цитаты
              </div>
            </div>
          </div>
        </div>
        <InfoDrop
          @openInner="openInner"
          :active="true"
          :arrowColor="'#fff'"
          :item="{
            title: `<span style='color: #fff'>Список сокращений</span>`,
            content: shortsList,
          }"
        />
      </div>
    </div>
    <PopUpDefault @close="popupActivity = false" v-if="popupActivity">
      <div class="event-detail__puzzle">
        <div class="event-detail__description mb-6" style="color: #000">
          Наши цели и ценности совпадают: мы нацелены на инновации в науке и
          ставим интересы пациентов превыше всего. Таких ориентиров
          придерживались великие деятели медицины и науки прошлого, эти
          устремления мы переносим в будущее.<br /><br />
          Предлагаем вам угадать, кому могут принадлежать цитаты ниже и как
          искуственный интеллект представляет нам новаторов из мира медицины,
          если бы они были нашими современниками.<br /><br />
          Сопоставьте цитату известного специалиста с его фотографией,
          перетаскивая и располагая карточки друг напротив друга.
        </div>
        <div>
          <iframe
            src="https://puzzel.org/en/matching-pairs/embed?p=-NUlUdGDDzm5hf5B9Loi"
            width="100%"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </PopUpDefault>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import KrepostEvent from "@/components/pageComponents/KrepostEvent.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
export default {
  name: "Krepost",
  data: () => ({
    timerResult: { id: 0 },
    interval: null,
    popupActivity: false,
  }),
  components: {
    KrepostEvent,
    InfoDrop,
    PopUpDefault,
  },
  computed: {
    ...mapGetters(["user"]),
    shortsList() {
      return `<div class="short-list" style="color: #fff">
      ХСН – хроническая сердечная недостаточность; <br><br>
ИБС – ишемическая болезнь сердца; <br><br>
АГ – артериальная гипертензия; <br><br>
ССЗ – сердечно-сосудистые заболевания; <br><br>
СД – сахарный диабет;<br><br>
СД2 – сахарный диабет 2-го типа; <br><br>
ХБП – хроническая болезнь почек;  <br><br>
ХОБЛ – хроническая обструктивная болезнь легких;<br><br>
БА – бронхиальная астма;  <br><br>
ТБА – тяжелая бронхиальная астма;<br><br>
ГИБП – генно-инженерные биологические препараты; <br><br>
ГЭРБ – гастроэзофагеальная рефлюксная болезнь;<br><br>
COVID-19 – коронавирусная инфекция 2019;<br><br>
СКВ – системная красная волчанка;<br><br>
РСВ – респираторно-синцитиальный вирус;<br><br>
РСВИ — Респираторно-синцитиальная вирусная инфекция;<br><br>
д.м.н. — Доктор медицинских наук;<br><br>
к.м.н. — Кандидат медицинских наук;<br><br>
РАН — Российская академия наук;<br><br>
СС — Сердечно-сосудистый;<br><br>
ДАТТ — двойная антитромбоцитарная терапия.
</div>`;
    },
    EventDetail() {
      return {
        id: 298, // 298 - dev 298 -prod
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        name: "Крепость здоровья",
        description: "",
        date: "2023-09-30T10:00:30+03:00",
        date_end: "2023-09-30T14:30:30+03:00",
        place: "",
      };
    },
    eventsList() {
      return [
        {
          title: "Кардиологический симпозиум",
          slug: "cardio",
          date: "2023-09-30T10:00:30+03:00",
          icons: [`https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/cardio.svg`],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/1.jpg`,
          license:
            "Номер одобрения: RU-18251 Дата одобрения: 15.09.2023 Дата истечения: 15.09.2025",
          program: [
            {
              title:
                "Кардиологический симпозиум «Сердце крепости. От профилактики до защиты»",
              description:
                "<b>Описание симпозиума:</b> 2023 год богат новостями и изменениями в подходах к терапии ХСН. Как выбрать оптимальную терапию бета-блокатором пациентам с ИБС или ХСН и не промахнуться. Выбор отпимального P2Y12 ингибитора: взвешиваем риски, принимаем решение. Определяем длительность ДАТТ у пациентов с высоким ишемическим риском. Основной шаг в гиполедимической терапии через призму рекомендаций 2023 года.<br><br><b>Председатель:</b> Дупляков Дмитрий Викторович, д.м.н., профессор",

              list: [
                {
                  title: "Вступительное слово",
                  description:
                    "Дупляков Дмитрий Викторович, д.м.н., профессор<br>Время выступления: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/duplyakov.png`,
                  time: "10:00 – 10:05",
                },
                {
                  title: "Главные новости этой осени в терапии ХСН",
                  description:
                    "Жиров Игорь Витальевич, д.м.н., профессор<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/jirov.png`,
                  time: "10:05 – 10:35",
                },
                {
                  title: "Мастер класс по бета-блокаторам при ХСН или ИБС",
                  description:
                    "Хадзегова Алла Блаловна, д.м.н., профессор<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/hadzegova.png`,
                  time: "10:35 – 10:55",
                },
                {
                  title: "Антиагрегантный дозор: снижаем СС риски",
                  description:
                    "Дупляков Дмитрий Викторович, д.м.н., профессор<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/duplyakov.png`,
                  time: "10:55 – 11:15",
                },
                {
                  title: "Миф о стабильной ИБС после инфаркта",
                  description:
                    "Козиолова Наталья Андреева, д.м.н., профессор<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/koziolova.png`,
                  time: "11:15 – 11:35",
                },
                {
                  title:
                    "Статины, железобетонный фундамент и что-то там наверху. Алгоритмы для практикующих врачей",
                  description:
                    "Сусеков Андрей Владимирович, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/susekov.png`,
                  time: "11:35 – 12:00",
                },
              ],
            },
          ],
        },
        {
          title: "Терапевтический симпозиум",
          date: "2023-09-30T10:00:30+03:00",
          slug: "therapy",
          icons: [
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/pulmo.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/immuno.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/cardio.svg`,
          ],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/2.jpg`,
          license:
            "Номер одобрения: RU-18250 Дата одобрения: 19.09.2023 Дата истечения: 19.09.2025",
          program: [
            {
              title:
                "Терапевтический симпозиум «Как услышать бронхиальную астму и проблемы связанные с ней»",
              description:
                "<b>Описание симпозиума:</b> Вместе с ведущими экспертами обсудим сложные вопросы диагностики, контроля и обострений бронхиальной астмы. Как связаны между собой бронхиальная астма и ГЭРБ и какие существуют нюансы в диагностике и лечении.<br><br><b>Председатель:</b> Адашева Татьяна Владимировна, д.м.н., профессор",

              list: [
                {
                  title: "Вступительное слово",
                  description: "Время выступления: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/person_yellow.svg`,
                  time: "10:00 – 10:05",
                },
                {
                  title:
                    "Алгоритм поиска бронхиальной астмы. Как найти и не пропустить",
                  description:
                    "Ненашева Наталья Михайловна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/nenasheva.png`,
                  time: "10:05 – 10:30",
                },
                {
                  title: '"Неконтроль" бронхиальной астмы. Найти и обезвредить',
                  description:
                    "Айсанов Заурбек Рамазанович, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/aysanov.png`,
                  time: "10:30 – 10:55",
                },
                {
                  title:
                    "На приеме пациент с обострением БА – клинические лайфхаки",
                  description:
                    "Бабак Сергей Львович, д.м.н. профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/babak.png`,
                  time: "10:55 – 11:20",
                },
                {
                  title:
                    "Пациент с бронхиальной астмой и ГЭРБ: что может быть общего? Как не ошибиться с диагнозом?",
                  description:
                    "Кайбышева Валерия Олеговна, к.м.н.<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/kaibisheva.png`,
                  time: "11:20 – 11:45",
                },
                {
                  title: "Перерыв",
                  description: "Время: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/coffee_yellow.svg`,
                  time: "11:45 – 11:50",
                },
              ],
            },
            {
              title:
                "Терапевтический симпозиум «Дела сердечные. Как услышать голос сердца»",
              description:
                "<b>Описание симпозиума:</b> ХСН может маскироваться под основным заболеванием. На что обратить внимание в клинической практике и не пропустить пациента с ХСН? Роль бета-блокаторов в лечении пациентов с ИБС. Значение особой формы ЗОК при выборе терапии данным пациентам. Своевременная оценка факторов риска ИБС и актуализация необходимости продления двойной антиагрегантной терапии (ДАТТ).<br><br><b>Председатель:</b> Адашева Татьяна Владимировна, д.м.н., профессор",
              list: [
                {
                  title: "Улучшаем диагностику ХСН: на что обратить внимание?",
                  description:
                    "Адашева Татьяна Владимировна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/adasheva.png`,
                  time: "11:50 – 12:15",
                },
                {
                  title:
                    "Эпоха перемен. Бескомпромиссный выбор б-блокаторов в терапии ИБС и АГ",
                  description:
                    "Хадзегова Алла Блаловна, д.м.н.<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/hadzegova.png`,
                  time: "12:15 – 12:40",
                },
                {
                  title: "На приеме пациент с ИБС: выявляем факторы риска",
                  description:
                    "Арутюнов Григорий Павлович, д.м.н.<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/arutynov.png`,
                  time: "12:40 – 13:05",
                },
              ],
            },
            {
              title:
                "Терапевтический симпозиум «Защита от COVID-19 и выявление пациентов с СКВ. Что нужно знать терапевтам»",
              description:
                "<b>Описание симпозиума:</b> Моноклональные антитела, как современная возможность предотвратить COVID-19. СКВ: как часто встречается заболевание, какие проявления бывают у системной красной волчанки, каких пациентов стоит перенаправить к ревматологу.",
              list: [
                {
                  title:
                    "Новости из красной зоны в 2023 году – как терапевту предотвратить COVID-19 у пациентов групп риска?",
                  description:
                    "Тавлуева Евгения Валерьевна, д.м.н.<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/tavlueva.png`,
                  time: "13:05 – 13:35",
                },
                {
                  title:
                    "Краткая история системной красной волчанки (СКВ): алгоритмы диагностики и лечения",
                  description:
                    "Новиков Павел Игоревич, к.м.н.<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/novikov.png`,
                  time: "13:35 – 14:05",
                },
              ],
            },
          ],
        },
        {
          title: "Педиатрический и неонатологический симпозиум",
          date: "2023-09-30T10:00:30+03:00",
          icons: [
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/neon.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/immuno.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/oncology.svg`,
          ],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/3.jpg`,
          license:
            "Номер одобрения: RU-18251 Дата одобрения: 15.09.2023 Дата истечения: 15.09.2025",
          slug: "pediatrics",
          program: [
            {
              title:
                "Педиатрический и неонатологический симпозиум «Крепкое здоровье формируется с детства. Актуальные вопросы педиатрии»",
              description:
                "<b>Описание симпозиума:</b> Бронхиальная астма у детей – диагностика и подходы к лечению. Терапия острого обструктивного ларингита (крупа). Как преодолеть трудности в диагностике ГЭРБ у детей в рамках педиатрического приёма и определить необходимый объем помощи конкретному пациенту. Группы риска тяжелого течения РСВ-инфекции с разбором клинических случаев. Способы защиты от РСВИ.<br><br><b>Председатель:</b> Зайцева Ольга Витальевна, д.м.н., профессор",

              list: [
                {
                  title: "Вступительное слово",
                  description:
                    "Зайцева Ольга Витальевна, д.м.н., профессор<br>Время выступления: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/zayceva.png`,
                  time: "10:00 – 10:05 ",
                },
                {
                  title:
                    "Бронхиальная астма у детей: своевременный диагноз и адекватная терапия – залог здоровья ребенка",
                  description:
                    "Зайцева Ольга Витальевна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/zayceva.png`,
                  time: "10:05 – 10:30 ",
                },
                {
                  title:
                    "Подход к лечению бронхиальной астмы у детей на основе биомаркеров",
                  description:
                    "Фурман Евгений Григорьевич, член-корреспондент РАН, профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/furman.png`,
                  time: "10:30 – 10:55",
                },
                {
                  title:
                    "Место ингаляционных глюкокортикостероидов в терапии острого обструктивного ларингита (крупа)",
                  description:
                    "Денисова Анита Робертовна, к.м.н<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/denisova.png`,
                  time: "10:55 – 11:20",
                },
                {
                  title:
                    "ГЭРБ: как не упустить проблему. Особое внимание к подросткам",
                  description:
                    "Мухаметова Евгения Маратовна, к.м.н<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/muhametova.png`,
                  time: "11:20 – 11:40",
                },
                {
                  title:
                    "Угрозы, риски и возможности защиты детей от РСВ-инфекции",
                  description:
                    "Басаргина Милана Александровна, к.м.н<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/basargina.png`,
                  time: "11:40 – 12:05",
                },
              ],
            },
          ],
        },
        {
          title: "Редкие заболевания у детей",
          date: "2023-09-30T12:10:30+03:00",
          icons: [`https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/oncology.svg`],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/4.jpg`,
          slug: "onko",
          license:
            "Номер одобрения: RU-18251 Дата одобрения: 15.09.2023 Дата истечения: 15.09.2025",
          program: [
            {
              title: "Редкие заболевания у детей",
              description:
                "<b>Описание симпозиума:</b> Ведущие эксперты в области орфанной патологии поделятся с практикующими педиатрами рекомендациями о том, как выявлять редкую патологию у детей и последними новостями в этой области",

              list: [
                {
                  title: "Как заподозрить гипофосфатазию?",
                  description:
                    "Калинченко Наталья Юрьевна, к.м.н.<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/person_violet-dark.svg`,
                  time: "12:10 – 12:40  ",
                },
                {
                  title:
                    "Увеличение печени неясного генеза: как исключить редкое заболевание?",
                  description:
                    "Строкова Татьяна Викторовна, д.м.н., профессор РАН<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/person_violet-dark.svg`,
                  time: "12:40 – 13:10",
                },
                {
                  title: "Клинические проявления нейрофиброматоза 1-го типа",
                  description:
                    "Мареева Юлия Михайловна, врач-детский онколог<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/person_violet-dark.svg`,
                  time: "13:10 – 13:40",
                },
              ],
            },
          ],
        },
        {
          title: "Пульмонологический симпозиум",
          date: "2023-09-30T13:45:30+03:00",
          icons: [
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/pulmo.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/immuno.svg`,
          ],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/5.jpg`,
          slug: "pulmo",
          license:
            "Номер одобрения: RU-18252 Дата одобрения: 18.09.2023 Дата истечения: 18.09.2025",
          program: [
            {
              title:
                "Пульмонологический симпозиум «Контролируй и властвуй над бронхиальной астмой»",
              description:
                "<b>Описание симпозиума:</b> Вместе с ведущими экспертами обсудим сложные вопросы контроля бронхиальной астмы всех степеней тяжести. От терапии по потребности при легкой астме до генно-инженерных биологических препаратов при тяжелом течении.<br><br><b>Председатель:</b> Айсанов Заурбек Рамазанович , д.м.н., профессор",

              list: [
                {
                  title: "Вступительное слово",
                  description:
                    "Айсанов Заурбек Рамазанович , д.м.н., профессор<br>Время выступления: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/aysanov.png`,
                  time: "13:45 – 13:50",
                },
                {
                  title:
                    '"Неконтроль" бронхиальной астмы в вопросах и ответах. Все, что вы хотели узнать, но боялись спросить',
                  description:
                    "Синопальников Александр Игоревич, д.м.н., профессор<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/sinopalnikov.png`,
                  time: "13:50 – 14:10",
                },
                {
                  title:
                    "Управление контролем БА: стратегия и тактика на пути. Разбор клинических случаев",
                  description:
                    "Белоцерковская Юлия Геннадьевна, к.м.н.<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/belocerkovskaya.png`,
                  time: "14:10 – 14:30",
                },
                {
                  title: "Тяжелая бронхиальная астма и ГИБП: ключевые аспекты",
                  description:
                    "Белевский Андрей Станиславович, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/belevskiy.png`,
                  time: "14:30 – 14:55",
                },
                {
                  title:
                    "Практические вопросы применения ГИБП у пациентов с ТБА (клинический случай)",
                  description:
                    "Терпигорев Станислав Анатольевич, д.м.н., профессор<br>Время выступления: 20 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/terpigorev.png`,
                  time: "14:55 – 15:20",
                },
                {
                  title: "Перерыв",
                  description: "Время: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/coffee_green.svg`,
                  time: "15:20 - 15:25",
                },
              ],
            },
            {
              title: "Пульмонологический симпозиум «ХОБЛ: Мифы о драконах»",
              description:
                "<b>Описание симпозиума:</b> В рамках симпозиума ХОБЛ будет рассмотрена как социально значимая проблема. Эксперты представят обновленные рекомендации GOLD 2023 и новые подходы к терапии ХОБЛ. Сохраняется ли сейчас угроза COVID-19 для пациентов с ХОБЛ?<br><br><b>Председатель:</b> Айсанов Заурбек Рамазанович , д.м.н., профессор",
              list: [
                {
                  title: "Драконы и ХОБЛ, где взаимосвязь?",
                  description:
                    "Айсанов Заурбек Рамазанович , д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/aysanov.png`,
                  time: "15:25 – 15:50",
                },
                {
                  title: "Защита от COVID-19 в 2023 году",
                  description:
                    "Романовских Анна Геннадьевна, к.м.н.<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/romanovskih.png`,
                  time: "15:50 – 16:20",
                },
              ],
            },
          ],
        },
        {
          title: "Эндокринологический симпозиум",
          date: "2023-09-30T12:05:30+03:00",
          icons: [
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/endo.svg`,
            `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/endo2.svg`,
          ],
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back/6.jpg`,
          slug: "endo",
          license:
            "Номер одобрения: RU-18251 Дата одобрения: 15.09.2023 Дата истечения: 15.09.2025",
          program: [
            {
              title:
                "Эндокринологический симпозиум «Кардио-рено-метаболический бастион. От СД 2 типа до ХБП»",
              description:
                "<b>Описание симпозиума:</b> Болезнь-модифицирующая модель терапии СД 2 типа: ранняя интенсификация терапии, управление рисками сердечно-сосудистых и почечных осложнений. Как моноклональные антитела могут обеспечить дополнительную защиту уязвимым группам пациентов.<br><br><b>Председатель:</b> Демидова Татьяна Юльевна, д.м.н.",

              list: [
                {
                  title: "Вступительное слово",
                  description:
                    "Демидова Татьяна Юльевна, д.м.н., профессор<br>Время выступления: 5 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/demidova.png`,
                  time: "12:05 – 12:10",
                },
                {
                  title: "Ранняя интенсификация терапии СД 2-го типа",
                  description:
                    "Демидова Татьяна Юльевна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/demidova.png`,
                  time: "12:10 – 12:35",
                },
                {
                  title:
                    "Фиксированные комбинации на ранних этапах как залог успешной терапии СД 2-го типа",
                  description:
                    "Маркова Татьяна Николаевна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/markova.png`,
                  time: "12:35 – 13:00 ",
                },
                {
                  title:
                    "Ключевые аспекты ведения пациента с сахарным диабетом 2-го типа и хронической сердечной недостаточности",
                  description:
                    "Калашников Виктор Юрьевич, член-корреспондент РАН, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/kalashnikov.png`,
                  time: "13:00 – 13:25",
                },
                {
                  title: "ХБП независимо от наличия СД2. Клинические аспекты",
                  description:
                    "Бобкова Ирина Николаевна, д.м.н., профессор<br>Время выступления: 25 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/bobkova.png`,
                  time: "13:25 – 13:50",
                },
                {
                  title:
                    "Сахарный диабет, поражение почек и Covid-19 – тройная угроза",
                  description:
                    "Шамхалова Минара Шамхаловна, д.м.н.<br>Время выступления: 30 минут",
                  icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/icons/shamhalova.png`,
                  time: "13:50 – 14:20",
                },
              ],
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchEventsCalendar"]),
    toWidget(type, name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "krepost", {
          krepost: {
            "quotes click": {
              ...vm.$root.ymFields,
            },
          },
        });
      }
      
      this.popupActivity = true;
    },
    openInner(programm) {
      if (programm) {
        // if (typeof ym !== "undefined") {
        //   ym(91468266, "reachGoal", "forsiga", {
        //     forsiga: {
        //       "program drill down": {
        //         ...this.$root.ymFields,
        //       },
        //     },
        //   });
        // }
      } else {
        // if (typeof ym !== "undefined") {
        //   ym(91468266, "reachGoal", "forsiga", {
        //     forsiga: {
        //       "abbreviation click": {
        //         ...this.$root.ymFields,
        //       },
        //     },
        //   });
        // }
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
   
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "krepost", {
            krepost: {
              "reg click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      } else {
      }
      localStorage.setItem("source", `krepost3009`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "Krepost" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
      window.open(this.$root.ssoLinks.registration, "_self");
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  mounted() {
    document.querySelector(
      "#app"
    ).style.backgroundImage = `url(https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/krepost/back.jpg)`;
  },
  beforeDestroy() {
    document.querySelector("#app").style.backgroundImage = "";
  },
};
</script>

<style lang="scss" scoped>
.first-banner {
  position: relative;
  overflow: hidden;
  background-color: #e1f6f9;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
    height: 513px;
  }

  &__title {
    color: #ffffff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__description {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__button {
    margin-top: 32px;
    width: fit-content;
    padding: 8px 24px;
    color: #003865;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1220px) {
      padding: 40px;
    }

    @media screen and (max-width: 767px) {
      padding: 24px 16px;
      margin-top: auto;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1220px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 1220px) {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__warning {
    padding: 16px;
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    background: linear-gradient(
      90deg,
      rgba(66, 102, 172, 0.2) 25.41%,
      rgba(74, 122, 195, 0.2) 52.43%,
      rgba(96, 169, 227, 0.2) 100.88%
    );
    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }

  &__description {
    color: #fff;
    /* Mobile/Body/Regular/M */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }

  &__tip {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background: linear-gradient(
      90deg,
      rgba(66, 102, 172, 0.2) 25.41%,
      rgba(74, 122, 195, 0.2) 52.43%,
      rgba(96, 169, 227, 0.2) 100.88%
    );
    // background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    // &::before,
    // &::after {
    //   width: 100%;
    //   height: 22px;
    //   position: absolute;
    //   left: 0;
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    //   background-color: #f3fdff;
    //   display: block;
    //   content: "";
    // }

    // &::before {
    //   top: 0;
    //   background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer_t.png');
    //   background-position: bottom left;
    // }

    //  &::after {
    //   bottom: 0;
    //   background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer_b.png');
    //   background-position: top left;
    // }

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      // align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #003865;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    background-color: #336084;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}

.events-list {
  display: grid;
  gap: 32px;
  width: 100%;
  grid-template-columns: repeat(2, calc(50% - 16px));

  @media screen and (max-width: 1220px) {
    grid-template-columns: 100%;
  }

  &__title {
    color: #fff;
    /* Desktop/Heading/H2 */
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 126.087% */
  }
}

.hobl {
  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: linear-gradient(
        90.02deg,
        rgba(66, 102, 172, 0.2) 25.41%,
        rgba(74, 122, 196, 0.2) 52.43%,
        rgba(96, 169, 227, 0.2) 100.88%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.event-detail {
  &__puzzle {
    & iframe {
      margin: 0 auto;
      width: 100%;
      height: 50vh;

      @media screen and (max-width: 1220px) {
        width: 100%;
        // height: 500px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        // height: 300px;
      }
    }
  }
}
</style>