<template>
  <div class="event-block" :style="`background-image: url(${event.image})`">
    <div class="event-block__info">
      <div class="event-block__title" v-html="event.title"></div>
      <div
        class="event-block__tip"
        v-if="!!!Object.keys(user).length && !!$route.query.access"
      >
        <svg
          class="mr-2"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.5 12.7156C20.5 17.41 16.6944 21.2156 12 21.2156C7.30558 21.2156 3.5 17.41 3.5 12.7156C3.5 8.02116 7.30558 4.21558 12 4.21558C16.6944 4.21558 20.5 8.02116 20.5 12.7156ZM22 12.7156C22 18.2384 17.5228 22.7156 12 22.7156C6.47715 22.7156 2 18.2384 2 12.7156C2 7.19273 6.47715 2.71558 12 2.71558C17.5228 2.71558 22 7.19273 22 12.7156ZM12.427 6.71558C12.7756 6.71558 13 7.02361 13 7.38599V12.9245C13 13.2869 12.7756 13.5949 12.427 13.5949H11.5904C11.2418 13.5949 11 13.2869 11 12.9245V7.38599C11 7.02361 11.2418 6.71558 11.5904 6.71558H12.427ZM12 17.7156C11.4477 17.7156 11 17.2679 11 16.7156C11 16.1633 11.4477 15.7156 12 15.7156C12.5523 15.7156 13 16.1633 13 16.7156C13 17.2679 12.5523 17.7156 12 17.7156Z"
            fill="white"
          />
        </svg>
        Внимание, трансляция будет доступна только для авторизованных
        пользователей
      </div>
      <div v-else>
        <div v-if="Object.keys(timerResult).length && !timerResult.days">
          <div class="event-block__timer-title">Трансляция начнется через:</div>
          <div class="event-block__timer-row">
            <div class="event-block__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ helpers.etNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div class="event-block__timer-item" v-if="timerResult.minutes > 0">
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div class="event-block__timer-item" v-if="timerResult.seconds > 0">
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="!Object.keys(timerResult).length && !ended"
          class="event-block__start"
        >
          <svg
            class="mr-2"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.7153" r="2" fill="white" />
            <path
              d="M9.30983 17.3975C9.14475 17.6849 8.77588 17.7863 8.50647 17.5934C7.81696 17.0996 7.23803 16.4638 6.81016 15.7262C6.28164 14.8152 6.00223 13.7812 6.00001 12.7279C5.9978 11.6747 6.27286 10.6395 6.79756 9.72625C7.22232 8.98696 7.79857 8.34872 8.486 7.85201C8.7546 7.65794 9.12389 7.75785 9.29018 8.04448C9.45647 8.3311 9.35608 8.69544 9.09242 8.89617C8.5849 9.28256 8.15781 9.76752 7.83805 10.3241C7.41829 11.0546 7.19824 11.8828 7.20001 12.7254C7.20178 13.568 7.42531 14.3953 7.84813 15.1241C8.17023 15.6793 8.59935 16.1624 9.10849 16.5467C9.37299 16.7463 9.47491 17.1102 9.30983 17.3975Z"
              fill="white"
            />
            <path
              d="M7.07675 20.0862C6.90807 20.4031 6.51293 20.5253 6.20901 20.3342C5.03904 19.5984 4.0516 18.6014 3.32639 17.4181C2.48771 16.0496 2.03026 14.4817 2.00145 12.8769C1.97264 11.2721 2.37352 9.68877 3.16255 8.29104C3.84481 7.08243 4.79583 6.05061 5.93865 5.27337C6.23551 5.07148 6.63478 5.17935 6.81473 5.49001C6.99467 5.80066 6.88684 6.19616 6.59246 6.40166C5.64912 7.0602 4.86295 7.9235 4.29469 8.93015C3.61965 10.126 3.27667 11.4806 3.30132 12.8535C3.32597 14.2265 3.71734 15.5679 4.43487 16.7387C5.03889 17.7243 5.85554 18.5589 6.82191 19.1831C7.12347 19.3779 7.24543 19.7693 7.07675 20.0862Z"
              fill="white"
            />
            <path
              d="M16.9232 20.1087C17.0919 20.4256 17.4871 20.5477 17.791 20.3566C18.961 19.6209 19.9484 18.6239 20.6736 17.4405C21.5123 16.072 21.9697 14.5041 21.9986 12.8993C22.0274 11.2945 21.6265 9.71123 20.8375 8.31351C20.1552 7.10489 19.2042 6.07307 18.0614 5.29583C17.7645 5.09394 17.3652 5.20182 17.1853 5.51247C17.0053 5.82312 17.1132 6.21862 17.4075 6.42412C18.3509 7.08266 19.137 7.94596 19.7053 8.95261C20.3804 10.1484 20.7233 11.503 20.6987 12.876C20.674 14.249 20.2827 15.5904 19.5651 16.7612C18.9611 17.7468 18.1445 18.5813 17.1781 19.2056C16.8765 19.4004 16.7546 19.7918 16.9232 20.1087Z"
              fill="white"
            />
            <path
              d="M14.6902 17.3975C14.8553 17.6849 15.2241 17.7863 15.4935 17.5934C16.183 17.0996 16.762 16.4638 17.1898 15.7262C17.7184 14.8152 17.9978 13.7812 18 12.7279C18.0022 11.6747 17.7271 10.6395 17.2024 9.72625C16.7777 8.98696 16.2014 8.34872 15.514 7.85201C15.2454 7.65794 14.8761 7.75785 14.7098 8.04448C14.5435 8.3311 14.6439 8.69544 14.9076 8.89617C15.4151 9.28256 15.8422 9.76752 16.162 10.3241C16.5817 11.0546 16.8018 11.8828 16.8 12.7254C16.7982 13.568 16.5747 14.3953 16.1519 15.1241C15.8298 15.6793 15.4007 16.1624 14.8915 16.5467C14.627 16.7463 14.5251 17.1102 14.6902 17.3975Z"
              fill="white"
            />
          </svg>
          Трансляция началась
        </div>
      </div>
      <div class="event-block__buttons" v-if="!Object.keys(timerResult).length">
        <div
          @click="openIframe()"
          class="event-block__button button button_white-blue px-8 mb-4"
        >
          {{ended ? 'Смотреть' : 'Смотреть трансляцию'}}
        </div>
        <div
        v-if="!ended"
          class="button button_empty-white px-8"
          @click="openProgram(event.title)"
        >
          Программа
        </div>
      </div>
      <div
        v-else
        class="event-block__button button button_white-blue px-8"
        @click="openProgram(event.title)"
      >
        Программа
      </div>
    </div>
    <div class="event-block__icons">
      <img :src="icon" v-for="(icon, ind) in event.icons" :key="ind" />
    </div>
    <PopUpDefault @close="programState = false" v-if="programState">
      <div v-html="programHTML"></div>
    </PopUpDefault>
    <Facecast
      :slug="event.slug"
      :record="event.record"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'krepost3009'"
      :backUrl="{ name: 'Krepost' }"
      @close="closePopup"
      v-if="popup && $route.query.access"
    />
  </div>
</template>

<script>
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import { mapGetters } from "vuex";
export default {
  name: "KrepostEvent",
  props: {
    event: Object,
    ended: Boolean,
  },
  components: {
    PopUpDefault,
    Facecast,
    PopupEnd,
  },
  data: () => ({
    programState: false,
    timerResult: { id: 0 },
    interval: null,
    programTitle: null,
    openFrame: false,
    popup: false,
  }),
  computed: {
    ...mapGetters(["user"]),
    programHTML() {
      let html = ``;
      let block = ``;
      for (let program of this.event.program) {
        block = ``;
        for (let item of program.list) {
          block += `
              <div class="event__program-item">
                <div class="event__program-time">${item.time}</div>
                <div class="d-flex align-start event__program-info">
                  <img class="mr-2" src="${item.icon}"  />
                  <div class="event__program-info my-auto">
                    <div class="event__program-info-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="event__program-info-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
        }
        html += `
      <div class="event__program mb-8">
        <div class="event__program-caption">${program.title}</div>
        <div class="event__program-description">${program.description}</div>
        <div class="event__program-tip">(указано московское время)</div>
        ${block}
        
      </div>`;
      }
      html += `<div class="event__program-tip">${this.event.license}</div>`;
      return html;
    },
  },
  methods: {
    closePopup() {
      this.popup = false;
      this.openFrame = false;
    },
    openIframe() {
      const vm = this
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "krepost", {
            krepost: {
              "watch broadcast": {
                [vm.event.title]: {
                  ...vm.$root.ymFields,
                },
              },
            },
          });
        }
          this.openFrame = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "krepost", {
          krepost: {
            "watch broadcast": {
              [vm.event.title]: {
                ...vm.$root.ymFields,
              },
            },
          },
        });
      }
        this.openFrame = true;
      }
    },
    openProgram(title) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "krepost", {
          krepost: {
            "programm click": {
              [vm.event.title]: {
                ...vm.$root.ymFields,
              },
            },
          },
        });
      }
      this.programState = true;
      this.programTitle = title;
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.event.date)
        .utcOffset(+3)
        .subtract(30, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
};
</script>

<style lang="scss" scoped>
.event-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
  background-size: cover;
  background-position: bottom right;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 24px 16px;
    flex-direction: column-reverse;
  }

  &__icons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 50%;

    @media screen and (max-width: 767px) {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 16px;
    }

    & img {
      width: 120px;
      margin-right: -20%;
      display: block;
      flex-shrink: 0;

      @media screen and (max-width: 767px) {
        width: 80px;
        margin-right: 30px;
      }

      &:only-child {
        margin-right: 0;
        width: 180px;

        @media screen and (max-width: 767px) {
          width: 100px;
        }
      }

      &:nth-child(1) {
        z-index: 5;
      }

      &:nth-child(2) {
        z-index: 4;
      }

      &:nth-child(3) {
        z-index: 3;
      }

      &:nth-child(4) {
        z-index: 2;
      }

      &:nth-child(5) {
        z-index: 1;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__info {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;

    @media screen and (max-width: 767px) {
      max-width: none;
      height: auto;
      width: 100%;
    }
  }

  &__buttons {
    margin-top: auto;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #fff;
  }

  &__button {
    color: #003865;
    margin-top: auto;
  }

  &__tip {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;

    & svg {
      flex-shrink: 0;
    }
  }

  &__timer-row {
    margin-bottom: 24px;
    display: flex;
  }

  &__timer-item {
    margin-right: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.24);

    &:last-child {
      margin-right: 0;
    }
  }

  &__timer-title {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    margin-bottom: 16px;
  }

  &__start {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }
}

.timer {
  &__value {
    margin-right: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 29px;
    color: #fff;
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #fff;
  }
}

.button_empty-white:hover {
  color: #003865;
}
</style>

<style lang="scss">
.event {
  &__program {
    &-caption {
      margin-bottom: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      color: #1f1f1f;
    }
    &-tip {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #b2b4b4;
    }
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1f1f1f;
      padding: 4px 8px;
      border-radius: 99px;
      background-color: #ebefee;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &-title {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        // color: #ffffff;
        color: #3c4242;
      }

      &-description {
        margin-top: 8px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        // color: #b2b4b4;
        color: #656969;
      }
    }

    &-description {
      margin: 16px 0px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #3c4242;

      & b {
        font-weight: 500;
      }
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
</style>